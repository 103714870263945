<template>
  <div class="invoice-help">
    <invoice-header text="开票帮助"></invoice-header>
    <div class="invoice-help__content">
      <p>
        <span>1</span>
        <span>喵啰咿预计在1-2个工作日内将电子发票推送至您的邮箱</span>
      </p>
      <p>
        <span>2</span>
        <span>根据相关政策规定，发票内容仅支持开具“*信息技术服务*信息服务费”</span>
      </p>
      <p>
        <span>3</span>
        <span>根据相关政策规定，我司仅可以为您开具个人名义抬头的发票</span>
      </p>
      <p>
        <span>4</span>
        <span>根据相关政策规定，我司单份发票最高开票限额一万元</span>
      </p>
      <p>
        <span>5</span>
        <span>
          喵啰咿客服QQ为
          <strong>{{qq}}</strong>
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import {getKfqq} from './invoice'

export default {
  components: {
    "invoice-header": require("./header").default
  },
  created() {
      getKfqq().then(res=>{
        this.qq=res;
      })
  },
  data() {
    return {
      qq: ""
    };
  }
};
</script>

<style lang="scss">
.invoice-help {
  &__content {
    margin: 0.68rem 0.36rem;
    p {
      font-size: 0.208rem;
      line-height: 0.56rem;
      color: #666666;

      span:nth-child(1) {
        display: inline-block;
        width: 0.26rem;
        height: 0.26rem;
        margin-right: 0.12rem;
        background-color: #20a3dc;
        color: white;
        text-align: center;
        line-height: 1;
        border-radius: 50%;
      }

      strong{
        color:#20a3dc;
        font-weight: normal;
      }
    }
  }
}
</style>
