<!-- 可以的话，未来把这个提取成全局头部组件 -->
<template>
  <div class="invoice-header">
    <div class="invoice-header__left" @click="onLeftClick"></div>
    <div class="invoice-header__text">{{text}}</div>
    <div class="invoice-header__right"></div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    returnRouteName: {
      type: String,
      required: false
    }
  },
  methods: {
    onLeftClick() {
      if (this.returnRouteName) {
        this.$router.push({ name: this.returnRouteName });
      } else {
        this.$router.go(-1);
      }
    }
  }
};
</script>

<style lang="scss">
.invoice-header {
  position: sticky;
  top: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #20a3dc;
  padding: 0.3rem 0;

  &__left {
    cursor: pointer;
    background-size: contain;
    background-position: right center;
    background-repeat: no-repeat;
    background-image: url(~@/assets/icon_left_arrow.png);
  }
  &__text {
    font-size: 0.417rem;
    color: #ffffff;
  }
  // &__right{

  // }
  &__left,
  &__right {
    width: 0.4rem;
    height: 0.32rem;
  }
}
</style>
