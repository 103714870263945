import axios from "axios";

let basePath = "";
if (process.env.NODE_ENV !== 'production') {
  basePath = "http://gm.254game.com:8080/sdk";
} else {
  basePath = "http://gm.254game.com:8080/sdk";
}

// 获取客服qq
export function getKfqq() {
  return axios.get(basePath + '/user_info/getKfqq.do');
}

// 获取可以开发票的订单列表
export function getOrder(page, pageSize) {
  return axios.get(basePath + "/invoice/getOrder.do", {
    params: {
      username: GlobalObject.InvoiceUser.username,
      page,
      pageSize
    }
  })
}

// 保存发票信息，也可以是执行开发票
export function saveInvoice(pay_no, mailbox) {
  return axios.post(basePath + "/invoice/saveInvoice.do", {
    username: GlobalObject.InvoiceUser.username,
    pay_no,
    mailbox
  })
}

// 发送验证码
export function sendVerifyCode(phone) {
  return axios.post(basePath + "/invoice/getVerifyNum.do", {
    phone,
    username: GlobalObject.InvoiceUser.username
  })
}

// 验证手机
export function verifyPhone(phone, verifyCode) {
  return axios.post(basePath + "/invoice/verifyPhone.do", {
    username: GlobalObject.InvoiceUser.username,
    phone,
    verifyCode,
  })
}

// 获取状态：判断用户是否绑定手机和实名认证
export function getVerify(username, password) {
  return axios.post(basePath + '/invoice/login.do', {
    username,
    psw: password
  });
}


// 获取开票历史记录
export function getHistory(page, pageSize) {
  return axios.get(basePath + '/invoice/getHistory.do', {
    params: {
      username: GlobalObject.InvoiceUser.username,
      page,
      pageSize
    }
  })
}

export function getSuccessHistory(page, pageSize) {
  return axios.get(basePath + '/invoice/getSuccessHistory.do', {
    params: {
      username: GlobalObject.InvoiceUser.username,
      page,
      pageSize
    }
  })
}

export function getFailHistory(page, pageSize) {
  return axios.get(basePath + '/invoice/getFailHistory.do', {
    params: {
      username: GlobalObject.InvoiceUser.username,
      page,
      pageSize
    }
  })
}

// 重开发票
export function reApply(fpqqlsh) {
  return axios.post(basePath + '/invoice/reInvoice.do', {
    username: GlobalObject.InvoiceUser.username,
    fpqqlsh
  })
}


// 重发发票申请
export function resend(fpqqlsh, email) {
  return axios.post(basePath + '/invoiceReSend/save.do', {
    username: GlobalObject.InvoiceUser.username,
    fpqqlsh,
    email
  })
}
